import api from '../library/axios';

export default {
  getList() {
    return api({
      method: 'get',
      url: '/api/ws-products',
    });
  },
};
