<template>
  <el-dialog
    title="Syarat dan Ketentuan"
    :visible.sync="showModalTerms"
    width="50%"
    :show-close="false"
    :before-close="handleClose">
    <div style="height: 280px; overflow-y: scroll;">
      <span style="display: inline-block;">
        <h6>1. Ketentuan Umum</h6><br/>
        <p>BACALAH SELURUH KETENTUAN PENGGUNAAN INI SEBELUM MENGGUNAKAN DAN MENGAKSES WHATSAPP BUSINESS PLATFORM “WAPPIN”. <br/><br/>Ketentuan Penggunaan ini adalah perjanjian antara pengguna ("<b>Anda</b>") dan PT Mari Works Solution("<b>Kami</b>"), suatu badan hukum berbentuk perseroan terbatas yang didirikan dan dijalankan secara sah berdasarkan hukum negara Republik Indonesia dan berdomisili di DKI Jakarta, Indonesia. Ketentuan Penggunaan ini mengatur penggunaan Anda terhadap Wappin, situs web (www.//wappin.makna.ai/) dan situs web lain yang Kami kelola), konten disediakan oleh Kami dan/atau afiliasi Kami (secara bersama-sama disebut "<b>Wappin</b>"), serta penggunaan layanan yang tersedia pada Wappin yang disediakan oleh Kami dan/atau afiliasi Kami ("<b>Layanan</b>").<br/><br/>Dengan menyetujui ketentuan Penggunaan ini, Anda juga menyetujui Ketentuan Penggunaan tambahan dan perubahannya yang merupakan bagian tidak terpisahkan dari Ketentuan Penggunaan ini (secara bersama-sama, Ketentuan Penggunaan, Ketentuan Penggunaan tambahan, dan perubahannya disebut sebagai "<b>Ketentuan Penggunaan</b>").</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>2. Pendaftaran Pengguna</h6><br/>
        <p class="text-left">Untuk dapat menggunakan Wappin secara aman, Anda wajib mengakses situs web Wappin resmi pada alamat domain https//wappin.app/. <br/>Untuk dapat mengakses Layanan, Anda harus menyetujui Ketentuan Penggunaan ini dan Kebijakan Privasi Data, serta mendaftarkan diri Anda dengan menyediakan informasi yang Kami butuhkan. Kami akan meminta Anda untuk menyediakan informasi tentang nama pengguna, merk dagang, nama perusahaan, alamat perusahaan, alamat email, nomor telepon dan kata sandi untuk memulai pendaftaran, dan beberapa informasi seperti alamat email dan kata sandi akan selalu dibutuhkan sebelum dapat mengakses Wappin.<br/><br/>Setelah berhasil melakukan pendaftaran, sistem Kami akan menghasilkan email konfirmasi secara otomatis dan mengirimkannya kepada Anda melalui alamat email yang telah Anda daftarkan. Anda perlu melakukan aktivasi atas akun Anda dengan mengikuti instruksi yang tercantum pada email konfirmasi tersebut.<br/><br/>Jika Anda keluar dari akun Anda, maka Anda perlu memasukan alamat email dan kata sandi. Dalam hal Anda lupa kata sandi yang dibuat saat pendaftaran, maka Anda dapat mengakses fitur lupa kata sandi dan Anda perlu melakukan verifikasi kembali melalui email konfirmasi yang Kami kirimkan melalui alamat email Anda.</p><br/>
        <p>
          <b>2.1 Aktivasi Wappin yang didahului Quotation</b>
          <br/>Anda juga dapat mengkostumisasi jenis Layanan pada dashboard Wappin sesuai dengan kebutuhan Anda. Quotation akan selalu dibutuhkan sebelum Anda dapat menggunakan Wappin dengan fitur dan jenis Layanan yang Anda tentukan sendiri. Setelah Quotation disepakati oleh Anda, Kami membutuhkan waktu 1 x 24 jam untuk mempersiapkan dan membuat dashboard Wappin Anda dapat digunakan secara efektif.<br/><br/>Notifikasi akan selalu Kami kirimkan ke alamat email yang telah Anda daftarkan kepada Kami. Kami menghimbau bahwa Anda selalu menjaga alamat email terdaftar dan terasosiasi dengan Wappin agar tidak diakses oleh pihak-pihak yang tidak berwenang.
        </p><br/>
        <p class="font-weight-bold text-center text-wrap">
          ANDA MENYATAKAN DAN MENJAMIN BAHWA ANDA MEMILIKI KENDALI DAN TANGGUNG JAWAB PENUH TERHADAP ALAMAT EMAIL YANG ANDA DAFTARKAN DAN TERASOSIASI DENGAN WAPPIN. ANDA DILARANG UNTUK MEMBERITAHUKAN ALAMAT EMAIL DAN KATA KUNCINYA KEPADA PIHAK YANG TIDAK BERWENANG, BAHKAN KEPADA KAMI WALAUPUN KAMI MEMINTA, DAN KEPADA PIHAK LAIN YANG MENGAKU SEBAGAI PERWAKILAN KAMI.
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>3. Pernyataan dan Komitmen Pengguna</h6><br/>
        <p>Dalam rangka penyediaan Layanan yang aman, terjamin dan sesuai dengan peraturan yang berlaku, Anda wajib memberikan persetujuan atas pernyataan dan jaminan sebagai berikut:<br/>
          <ul>
            <li>Anda menyatakan dan menjamin bahwa Anda telah berumur minimal 18 (delapan belas) tahun atau sudah pernah menikah dan tidak berada di bawah kekuasaan orang tua, perwalian, atau pengampuan. Dalam hal Anda tidak memenuhi ketentuan tersebut namun Anda tetap menggunakan dan mengakses konten, Wappin, dan Layanan, Anda menyatakan dan menjamin bahwa seluruh tindakan Anda tersebut telah disetujui oleh orang tua, wali atau pengampu Anda. Anda menyatakan dan menjamin bahwa Anda tidak akan menggunakan hak yang diberikan oleh peraturan yang berlaku untuk membatalkan dan mencabut setiap dan seluruh permintaan dan persetujuan yang Anda telah berikan pada Kami melalui Wappin.</li>
            <li>Anda menyatakan dan menjamin bahwa Anda telah membaca, memahami dan menyetujui secara sukarela untuk tunduk dan melaksanakan seluruh syarat dan ketentuan pada Ketentuan Penggunaan ini.</li>
            <li>Anda menyatakan dan menjamin bahwa Anda hanya akan menggunakan Layanan pada Wappin untuk tujuan yang sah dan tidak melanggar hukum dengan didasari itikad baik dan kehati-hatian.</li>
          </ul>
        </p>
        <p>
          <b>3.1 Izin yang Anda berikan kepada Kami</b>
          <br/>
          <ul>
            <li>Anda memberikan izin yang bersifat non-eksklusif, tanpa royalti, dapat dialihkan, dan lintas negara kepada Kami untuk menggunakan, mendistribusikan, memodifikasi, menggandakan, menerjemahkan, dan membuat karya turunan dari konten yang Anda buat, unggah, mendistribusikan, menyimpan, dan secara public menampilkan konten yang Anda unggah, kirim, simpan, atau terima pada atau melalui Wappin, semata-mata untuk tujuan menyediakan, mengoperasikan, mengembangkan, memperbaharui, dan meningkatkan Wappin atau WhatsApp Business, serta meneliti dan mengembangkan layanan, fitur, atau penggunaan baru.</li>
            <li>Anda memberikan izin kepada Kami untuk meng-install pembaharuan Wappin pada gawai yang Anda gunakan.</li>
          </ul>
        </p>
        <p>
          <b>3.2 Tambahan Ketentuan dan Kebijakan</b>
          <br/>Anda menyatakan dan memahami bahwa untuk menyediakan Wappin kepada Anda, Kami bekerjasama, menggunakan dan mengintegrasikan layanan WhatsApp Business yang dikelola oleh WhatsApp LLC dan afiliasinya. Sehubungan dengan hal tersebut, Anda juga sepakat, menyatakan, dan menjamin bahwa Anda telah membaca, memahami, dan menyetujui secara sukarela untuk tunduk dan melaksanakan seluruh syarat dan ketentuan yang dipublikasikan oleh WhatsApp LLC dari waktu ke waktu beserta setiap perubahannya termasuk namun tidak terbatas pada:
          <ul>
            <li>WhatsApp Business Terms of Service (<a target="_blank" href="https://www.whatsapp.com/legal/business-terms">https://www.whatsapp.com/legal/business-terms</a>);</li>
            <li>WhatsApp Business Solution Terms (<a target="_blank" href="https://www.whatsapp.com/legal/business-solution-terms">https://www.whatsapp.com/legal/business-solution-terms</a>).</li>
          </ul>
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>4. Kebijakan Privasi Data</h6><br/>
        <p>Anda sepakat bahwa Anda memberikan kewenangan dan hak kepada kami untuk mengumpulkan, menyimpan, mengolah, memeroses, menggunakan informasi pribadi Anda yang Anda berikan pada saat Anda melakukan pendaftaran seperti data identitas, data kontak, dan data lokasi Anda dan selanjutnya akan tunduk pada Kebijakan Privasi Data yang merupakan bagian yang tidak terpisahkan dari Ketentuan Penggunaan ini.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>5. Aktivitas yang Dilarang</h6><br/>
        <p>
          <ul>
            <li>Anda dilarang menggunakan Wappin untuk tujuan, transaksi dan penggunaan yang melanggar hukum atau ketentuan yang berlaku.</li>
            <li>Anda dilarang menggunakan Wappin untuk tujuan mendistribusikan dan/atau membuat dapat diaksesnya informasi elektronik yang memiliki muatan:
              <ol type="a">
                <li>melanggar kesusilaan:</li>
                <li>penghinaan, pencemaran nama baik, pemerasan dan/atau pengancaman;</li>
                <li>Kampanye politik pada saat melakukan broadcast;</li>
                <li>narkotika dan obat-obatan terlarang;</li>
                <li>tembakau, rokok dan produk turunannya;</li>
                <li>suplemen yang tidak aman, tidak terdaftar dan/atau tidak memperoleh izin dari otoritas terkait;</li>
                <li>senjata, amunisi, atau bahan peledak;</li>
                <li>perdagangan hewan;</li>
                <li>alkohol;</li>
                <li>produk kesehatan; </li>
                <li>perjudian; </li>
                <li>barang atau jasa yang mengandung unsur kejahatan seperti penipuan, menyesatkan, kekerasan, pemaksaan, curang atau mengganggu;</li>
                <li>barang atau produk yang mengandung unsur seksual dan tindakan asusila;</li>
                <li>penjualan konten digital yang dapat diunduh (music, game, film dll), langganan digital (streaming internet, tv, netflix, spotify dll) atau akun digital (akun netflix atau akun game);</li>
                <li>penjualan perangkat yang memfasilitasi atau mendorong tindakan streaming konten digital secara tidak sah atau mengganggu fungsi perangkat elektronik; dan</li>
                <li>hal-hal lainnya yang ditentukan oleh WhatsApp LLC. di kemudian hari.</li>
              </ol>
            </li>
            <li>Anda dilarang baik secara langsung maupun tidak langsung untuk melakukan (a) mendistribusikan, menjual, menjual kembali, atau menyewakan Wappin kepada pihak ketiga; (b) mendistribusikan atau membuat layanan yang serupa dengan Wappin dan/atau WhatsApp; dan (c) menyalin, mereproduksi, mendistribusikan, menampilkan, memodifikasi, atau membuat karya turunan dari semua atau sebagian dari layanan Wappin dan/atau WhatsApp; lebih lanjut, Klien tidak boleh, secara langsung, tidak langsung, atau melalui sarana otomatis atau lainnya; (d) menghapus pemberitahuan atau tanda hak kepemilikan apapun; (e) merekayasa balik (reverse engineering) aspekk apa pun dari layanan Wappin dan/atau WhatsApp atau melakukan apa pun yang dapat menemukan source  code; (f) menarik atau mengekstrak data dari layanan Wappin dan/atau WhatsApp; (g) mengembangkan atau menggunakan aplikasi yang berinteraksi dengan layanan Wappin dan/atau WhatsApp tanpa persetujuan tertulis sebelumnya dari MWS; (h) membuat perangkat lunak atau API yang berfungsi secara substansial sama dengan layanan Wappin dan/atau WhatsApp dan menawarkannya untuk digunakan oleh pihak ketiga dengan cara yang tidak sah; dan (i) hal-hal lainnya yang ditentukan oleh WhatsApp LLC. di kemudian hari.</li>
            <li>Anda dilarang menggunakan Wappin untuk tujuan provokatif dan/atau menyebarkan berita bohong dan menyesatkan.</li>
            <li>Anda dilarang mencoba untuk membuat akun, mengakses, menghimpun informasi dengan cara yang tidak diizinkan termasuk dengan melakukan otomasi tanpa izin tertulis dari Kami.</li>
            <li>Anda dilarang untuk memposting informasi pribadi atau rahasia orang lain tanpa izin atau melakukan apa pun yang dapat melanggar hak orang lain termasuk hak kekayaan intelektual seperti pelanggaran hak cipta, hak merek dagang, dan lain sebagainya.</li>
            <li>Anda dilarang mengubah, menerjemahkan, membuat karya turunan, merekayasa balik layanan Kami tanpa persetujuan tertulis dari Kami.</li>
          </ul>
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>6. Biaya dan Pajak</h6><br/>
        <p>Anda sepakat bertanggung jawab atas seluruh paket data operator, biaya internet, dan biaya serta pajak lain yang terkait dengan penggunaan Anda atas Layanan dan Wappin.<br/><br/>Anda sepakat bahwa Kami dapat menerapkan dan/atau merubah biaya atas Layanan dan Wappin dengan didahului pemberitahuan sebelumnya kepada Anda. Anda dapat memberikan persetujuan perihal perubahan tersebut atau dalam hal Anda tidak menyetujui perubahan tersebut, Anda dapat secara langsung berhenti menggunakan Wappin atau menutup akun Anda.</p><br/>
        <p>
          <b>6.1 Balance Wappin dan Pengisian</b>
          <br/>Sebelum dapat menggunakan fitur pada dashboard Wappin, Anda harus menyediakan Balance Wappin yang secara mandiri dapat Anda isi dengan mengakses fitur “top-up” pada dashboard. Pengisian Balance Wappin dapat dilakukan dengan melakukan transfer sejumlah nominal uang (dalam mata uang Rupiah) melalui berbagai pilihan opsi Virtual Account (VA) dan opsi pembayaran melalui Kartu Kredit yang telah Kami sediakan. Nominal uang yang Anda kirimkan dalam beberapa saat akan langsung tertera sebagai Balance pada dashboard Wappin yang jumlah sama tanpa dikenakan pemotongan pajak.<br/><br/>Anda tidak dapat menggunakan fitur-fitur pada dashboard Wappin jika Balance Wappin habis atau kurang. Penggunaan fitur akan secara otomatis gagal jika sisa Balance Wappin yang Anda miliki tidak mencukupi.<br/><br/>Anda sepakat bahwa nominal uang yang telah menjadi Balance Wappin tidak dapat ditarik kembali dengan alasan apapun dan Anda hanya dapat mempergunakan Balance Wappin tersebut untuk menggunakan layanan atau fitur-fitur yang tersedia pada dashboard Wappin.
        </p><br/>
        <p>
          <b>6.2 Transaksi Penggunaan, Pajak Pertambahan Nilai (PPN) dan Pajak Penghasilan Pasal 23 (PPh 23)</b>
          <br/>PPN akan Kami kenakan kepada Anda untuk setiap transaksi yang dilakukan. Tagihan atau keterangan pembayaran transaksi akan Kami sediakan kepada Anda secara bulanan dengan nominal tanpa disertai dengan PPh 23.<br/><br/>Anda dapat menagih hak Anda atas pemotongan PPh23 kepada Kami dengan ketentuan bahwa Anda harus mengirimkan bukti potong PPh23 untuk transaksi selama 1 (satu) bulan berlalu dan menyertakannya melalui fitur “ticketing” yang tersedia pada dashboard Wappin maksimal 30 (tiga puluh) hari setelah transaksi terakhir di bulan sebelumnya.<br/><br/>Anda sepakat bahwa Nominal pemotongan PPh 23 akan Kami berikan kepada Anda melalui penambahan jumlah Balance Wappin dalam waktu 30 (tiga puluh) hari sejak bukti potong yang Anda sertakan Kami nyatakan benar. Kami dapat menolak bukti potong yang keliru dan meminta Anda untuk memperbaiki bukti potong agar benar dan sesuai.<br/><br/>Anda menyatakan, mengetahui dan menyepakati bahwa seluruh informasi terkait transaksi penggunaan dan pembayaran dapat Anda dapatkan secara mandiri melalui dashboard seluruhnya.
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>7. Gawai dan Perangkat</h6><br/>
        <p>Anda wajib menyediakan gawai, perangkat lunak, dan koneksi data tertentu yang Kami tidak sediakan kepada Anda untuk menggunakan Layanan dan Wappin. Anda sepakat untuk mengunduh dan menginstall pembaharuan-pembaharuan pada Wappin secara mandiri atau otomatis.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>8. Hak Kekayaan Intelektual</h6><br/>
        <p>Seluruh informasi dalam Wappin termasuk namun tidak berbatas pada nama, logo, desain, data, proses dan model bisnis, know-how dan seluruh informasi lainnya yang tersedia dalam Wappin atau website yang kami kelola adalah hak milik atau hak kekayaan intelektual yang dimiliki oleh Kami. Anda sepakat bahwa Anda tidak dapat menggunakan hak kekayaan intelektual Kami tanpa persetujuan Kami.<br/><br/>Kami memberikan Anda lisensi secara terbatas, tidak dapat dialihkan, dan non-eksklusif untuk mengunduh, meng-install, menggunakan, mengakses layanan dan Wappin Kami sebagaimana adanya untuk keperluan Anda dan tunduk pada ketentuan-ketentuan dalam Ketentuan Penggunaan ini.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>9. Pembatasan Tanggung Jawab</h6><br/>
        <p>
          <ul>
            <li>Kami menyediakan Layanan dan Wappin 'sebagaimana adanya' atau 'as is', dan Kami tidak menjamin bahwa Layanan dan Wappin akan aman, terjamin, berjalan baik setiap waktu. Wappin dapat mengalami keterbatasan, penundaan, dan masalah-masalah lain yang dapat terjadi dalam penggunaan internet dan komunikasi internet.</li>
            <li>Kami tidak mengontrol apa yang orang lakukan dan perbuat dan Kami tidak bertanggung-jawab atas tindakan atau perlakuan (baik dalam jaringan atau luar jaringan) atau konten (termasuk yang tidak dibenarkan oleh peraturan) mereka (atau Anda). Kami juga tidak bertanggung jawab atas layanan atau fitur yang ditawarkan oleh orang atau pihak lain, meskipun anda mengaksesnya melalui Wappin.</li>
            <li>Apabila terjadi kendala dengan Wappin, Kami tidak dapat mengetahui seluruh dampak yang diakibatkan. Anda sepakat bahwa Kami tidak bertanggung jawab atas kehilangan keuntungan, pendapatan, informasi atau data, seluruh kerugian yang timbul atau sehubungan dengan Ketentuan Penggunaan ini, meskipun Kami mengetahui hal tersebut mungkin terjadi. Hal yang sama termasuk pada saat Kami menghapus konten, informasi atau akun Anda.</li>
          </ul>
        </p>
        <p>
          <b>9.1 Tanggung Jawab Anda sebagai pengguna Wappin</b>
          <br/>Akun Anda hanya dapat digunakan oleh Anda dan Anda tidak dapat mengalihkan kepada orang lain dengan alasan apapun.
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>10. Penghapusan Konten dan Pengakhiran Penggunaan Wappin</h6><br/>
        <p>Kami dapat menghapus apapun konten atau informasi yang Anda bagikan melalui Wappin jika Kami berpendapat hal tersebut dapat melanggar Ketentuan Penggunaan ini. Kami dapat menolak untuk menyediakan atau berhenti menyediakan sebagian atau seluruh layanan kepada Anda (termasuk mengakhiri akses Anda terhadap Wappin) dengan segera untuk menjaga layanan dan/atau Wappin Kami.<br/><br>Sejauh yang diizinkan oleh hukum yang berlaku, Kami dapat menyimpan data dan informasi Anda meskipun Anda telah menutup akun Anda. Anda juga dapat meminta penghapusan data atau informasi pribadi Anda dengan mengirimkan permintaan Anda secara langsung ke team@mariworks.id.</p><br/>
        <p>
          <b>10.1 Deaktivasi Akun</b>
          <br/>Akun Anda akan aktif tanpa batas waktu kecuali Anda berkehendak untuk menutup akun Anda dengan mengakses fitur yang ada dalam Wappin atau mengirimkan permintaan Anda secara langsung ke team@mariworks.id.
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>11. Hukum yang Berlaku, Penyelesaian Masalah dan Sengketa</h6><br/>
        <p>Ketentuan Penggunaan ini diatur dan ditafsirkan menurut dan berdasarkan pada hukum dan peraturan yang berlaku di Negara Republik Indonesia.<br/><br/>Apabila Anda mengalami kendala atau masalah terkait Wappin, mengetahui atau menduga akun Anda diretas, digunakan atau disalahgunakan oleh orang atau pihak lain yang tidak Anda ketahui dan tidak anda izinkan, Anda dapat menyampaikan keluhan dan laporan Anda dengan segera melalui fitur yang kami sediakan dalam Wappin atau dengan menghubungi Kami pada data kontak Kami yang tersedia pada Ketentuan Penggunaan ini.<br/><br/>Untuk menyampaikan keluhan, pertanyaan, dan lainnya terkait penggunaan Wappin ("<b>Laporan</b>"), Anda perlu memberikan informasi benar, akurat, lengkap, dan disertai dengan bukti. Kami akan melakukan verifikasi dan menindaklanjuti Laporan yang telah Anda berikan. Kami dapat menolak untuk menindaklanjuti Laporan Anda jika informasi yang Anda berikan tidak benar, tidak lengkap, tidak akurat dan tanpa disertai dengan bukti yang Kami perlukan.<br><br>Anda dan Kami sepakat bahwa setiap dan seluruh sengketa yang timbul dari penggunaan Wappin tunduk pada mekanisme penyelesaian sengketa melalui Yurisdiksi Pengadilan Negeri Jakarta Selatan.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>12. Keadaan Kahar</h6><br/>
        <p>Anda mengetahui bahwa Wappin dapat diinterupsi oleh kejadian-kejadian di luar kewenangan, kemampuan, atau kontrol Kami ("<b>Keadaan Kahar</b>"), termasuk namun tidak terbatas pada bencana alam, gangguan listrik, gangguan telekomunikasi, kebijakan pemerintah dan kejadian lainnya dengan sifat semacam itu. Anda sepakat bahwa tidak ada pihak manapun yang harus bertanggung jawab atas kegagalan Kami untuk menyediakan baik seluruh atau sebagian layanan dalam Wappin.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>13. Bahasa</h6><br/>
        <p>Dalam hal Ketentuan Pengunaan ini ditampilkan dalam beragam pilihan bahasa dan terdapat ketidaksesuaian antara satu bahasa dengan bahasa yang lain, maka teks Bahasa Indonesia yang akan berlaku.</p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>14. Kontak dan Pemberitahuan</h6><br/>
        <p>
          <ol>
            <li>Anda dapat menghubungi Kami melalui surat elektronik ke team@mariworks.id. Anda sepakat bahwa semua korespondensi Anda akan dicatat, direkam, dan disimpan untuk keperluan peningkatan pelayanan dan arsip.</li>
            <li>Setiap pemberitahuan dari Anda yang ditujudkan kepada Kami menjadi efektif ketika pemberitahuan tersebut diterima oleh Kami melalui alamat surat elektronik (team@mariworks.id) dan/atau melalui dokumen fisik yang dikirimkan  ke PT Mari Works Solution dengan alamat Gedung Bulungan Business Center Lantai 1, Jalan Bulungan Nomor 15, RT.6/RW.6, Kramat Pela, Jakarta Selatan 12130, Indonesia.</li>
          </ol>
        </p>
      </span>

      <hr>
      <span style="display: inline-block; white-space: pre-wrap; word-break: break-word;">
        <h6>15. Perubahan Ketentuan Penggunaan ini</h6><br/>
        <p>Kami dapat merubah sebagian dan/atau seluruh ketentuan dan kebijakan dalam Ketentuan Penggunaan dan turunannya termasuk Kebijakan Privasi Data tanpa persetujuan Anda. Kecuali ditetapkan sebaliknya oleh hukum yang berlaku, Kami akan memberikan notifikasi kepada Anda sebelum Kami melakukan perubahan pada Ketentuan Penggunaan dan turunannya termasuk Kebijakan Privasi Data. Jika Anda tetap menggunakan layanan dan Wappin Kami, maka Anda akan terikat dengan Ketentuan Penggunaan dan Kebijakan Privasi Data terbaru. Namun jika Anda tidak sepakat, maka Anda dapat segera melakukan penutupan Akun Anda sesuai dengan Ketentuan Penggunaan ini.</p>
      </span>
    </div>
    <br/><br/>
    <b-form-checkbox
      id="checkbox-1"
      v-model="is_accept_terms">
      Saya setuju dengan Syarat dan Ketentuan
    </b-form-checkbox>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="loading_btn" :disabled="!is_accept_terms" size="small" type="primary" @click="handlerSubmit">Submit</el-button>
    </span>
  </el-dialog>
</template>
<script>
import auth from '../../../api/auth';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'TermsConditionWappinNew',
  data() {
    return {
      showModalTerms: false,
      is_accept_terms: false,
      loading_btn: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {},
    async handlerSubmit() {
      this.loading_btn = true;
      const response = await auth.accept_terms_condition_migration();
      this.loading_btn = false;
      await popupErrorMessages(response);
      this.showModalTerms = false;
      this.$parent.loadWorkspaces();
    },
  },
  watch: {
    show() {
      this.showModalTerms = this.show;
    },
  },
};
</script>
<style>
p {
  word-break: break-word;
}
</style>
